<template>
    <div class="">
        <Card class="p-m-2">
            <template #title> Şirketler </template>
            <template #content>
                <div class="top-button-area">
                    <Button icon="pi pi-plus " class="p-button-sm p-mr-2 p-mb-2 yeni" label="Yeni"
                        @click="_SirketModal()" />
                </div>
                <DataTable v-if="DataTable_render" :columns="columns" :data="SirketlerListesi"
                    v-on:set-child-data="getDataChild" />
            </template>
        </Card>

        <Dialog v-model:visible="SirketModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
            :style="{ width: '50vw' }" :modal="true">
            <template #header>
                <h4>Yeni Sirket</h4>
            </template>

            <div class="p-grid p-fluid p-formgrid">
                <div class="p-field p-col-6">
                    <span for="spanSirketAd">Şirket Ad</span>
                    <InputText type="text" v-model="SirketAd" aria-labelledby="spanSirketAd" />
                </div>
                <div class="p-field p-col-6">
                    <span for="spanAdSoyad">Ad Soyad</span>
                    <InputText type="text" v-model="AdSoyad" aria-labelledby="spanAdSoyad" />
                </div>
                <div class="p-field p-col-6">
                    <span for="spanUnvan">Ünvan</span>
                    <InputText type="text" v-model="Unvan" aria-labelledby="spanUnvan" />
                </div>
                <div class="p-field p-col-6">
                    <label for="TelefonNo">Telefon Numarası</label>
                    <InputMask id="TelefonNo" mask="(999) 999-9999" v-model="TelefonNo" />
                </div>
                <div class="p-field p-col-6">
                    <label for="CepTelefon1">Cep Telefon 1</label>
                    <InputMask id="CepTelefon1" mask="(999) 999-9999" v-model="CepTelefon1" />
                </div>
                <div class="p-field p-col-6">
                    <label for="CepTelefon2">Cep Telefon 2</label>
                    <InputMask id="CepTelefon2" mask="(999) 999-9999" v-model="CepTelefon2" />
                </div>
                <div class="p-field p-col-6">
                    <label for="MailAdres">Mail Adres</label>
                    <InputText id="MailAdres" type="text" v-model="MailAdres" />
                </div>
                <div class="p-field p-col-6">
                    <label for="WebAdres">Web Adres</label>
                    <InputText id="WebAdres" type="text" v-model="WebAdres" />
                </div>
                <div class="p-field p-col-6">
                    <label for="Adres">Şirket Adresi</label>
                    <InputText id="Adres" type="text" v-model="Adres" />
                </div>
                <Divider />
                <div class="p-field p-col-6">
                    <label for="BankaSube">Banka Şube</label>
                    <InputText id="BankaSube" type="text" v-model="BankaSube" />
                </div>
                <div class="p-field p-col-6">
                    <label for="HesapSahip">Hesap Sahibi</label>
                    <InputText id="HesapSahip" type="text" v-model="HesapSahip" />
                </div>
                <div class="p-field p-col-12">
                    <label for="Iban">IBAN</label>
                    <InputText id="Iban" type="text" v-model="Iban" />
                </div>
            </div>

            <template #footer>
                <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success"
                    @click="this.handleClick()" autofocus />
                <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger"
                    @click="this._SirketModal()" />
            </template>
        </Dialog>
    </div>
</template>

<script>
import DataTable from "../../DataTable/DataTable.vue";
import GlobalServis from "../../../../services/GlobalServis";

export default {
    data() {
        return {
            windowHeight: window.innerHeight,
            DataTable_render: true,
            SirketlerListesi: [],
            SirketModal: false,
            SirketPopUpDurumu: { type: Boolean, value: null }, // True = Yeni Sirket, False = Edit Sirket
            columns: [
                { field: "SirketAd", header: "Şirket Ad" },
                { field: "OlusturmaTarih", header: "Oluşturma Tarihi" },
            ],
            SirketAd: null,
            Unvan: null,
            AdSoyad: null,
            TelefonNo: null,
            CepTelefon1: null,
            CepTelefon2: null,
            MailAdres: null,
            WebAdres: null,
            Adres:null,
            BankaSube: null,
            HesapSahip: null,
            Iban: null,
        };
    },
    methods: {
        getDataChild(getdata_child) {
            this.SirketPopUpDurumu = false;
            this.SirketModal = !this.SirketModal;
            this.Sirket_rowid = getdata_child.data.Sirket_rowid;
            let data = {
                Sirket_rowid: getdata_child.data.Sirket_rowid,
            };
            GlobalServis.GlobalServis("POST", "GetSirketInfo", data).then(
                (res) => {
                    if (res.status == 200) {
                        this.Unvan = res.data[0].Unvan;
                        this.AdSoyad = res.data[0].AdSoyad;
                        this.SirketAd = res.data[0].SirketAd;
                        this.TelefonNo = res.data[0].TelefonNo;
                        this.CepTelefon1 = res.data[0].CepTelefon1;
                        this.CepTelefon2 = res.data[0].CepTelefon2;
                        this.MailAdres = res.data[0].MailAdres;
                        this.WebAdres = res.data[0].WebAdres;
                        this.Adres = res.data[0].Adres;
                        this.BankaSube = res.data[0].BankaSube;
                        this.HesapSahip = res.data[0].HesapSahip;
                        this.Iban = res.data[0].Iban;
                    } else if (res.status == 400) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Hata",
                            detail: "Önemli bir hata meydana geldi",
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Hata",
                            detail: "Bilinmeyen bir hata meydana geldi",
                            life: 3000,
                        });
                    }
                }
            );
        },
        handleClick() {
            let data = {
                Sirket_rowid: this.Sirket_rowid,
                SirketAd: this.SirketAd,
                Unvan: this.Unvan,
                AdSoyad : this.AdSoyad,
                TelefonNo: this.TelefonNo,
                CepTelefon1: this.CepTelefon1,
                CepTelefon2: this.CepTelefon2,
                MailAdres: this.MailAdres,
                WebAdres: this.WebAdres,
                Adres: this.Adres,
                BankaSube: this.BankaSube,
                HesapSahip: this.HesapSahip,
                Iban: this.Iban,
            };
            if (this.SirketPopUpDurumu) {
                // Yeni Sirket
                GlobalServis.GlobalServis("POST", "SetSirket", data).then(
                    (res) => {
                        if (res.status == 200 && res.data == "") {
                            this.$toast.add({
                                severity: "success",
                                summary: "Başarılı",
                                detail: "Sirket oluşturulmuştur",
                                life: 3000,
                            });
                            this.Refresh();
                            this._SirketModal();
                        } else if (res.status == 200 && res.data != "") {
                            this.$toast.add({
                                severity: "warn",
                                summary: "Uyarı",
                                detail: res.data,
                                life: 3000,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Hata",
                                detail: "Hata oldu",
                                life: 3000,
                            });
                        }
                    }
                );
            } else {
                // Edit Sirket
                GlobalServis.GlobalServis("POST", "UpdateSirket", data).then(
                    (res) => {
                        if (res.status == 200 && res.data == "") {
                            this.$toast.add({
                                severity: "success",
                                summary: "Başarılı",
                                detail: "Sirket düzenlemiştir",
                                life: 3000,
                            });
                            this.Refresh();
                            this._SirketModal();
                        } else if (res.status == 200 && res.data != "") {
                            this.$toast.add({
                                severity: "warn",
                                summary: "Uyarı",
                                detail: res.data,
                                life: 3000,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Hata",
                                detail: "Hata oldu",
                                life: 3000,
                            });
                        }
                    }
                );
            }
        },
        _SirketModal() {
            this.SirketPopUpDurumu = true;
            this.SirketModal = !this.SirketModal;
            if (!this.SirketModal) {
                this.resetForm();
            }
        },
        resetForm() {
            // this.v$.$reset;
            this.SirketAd = null;
            this.Unvan = null;
            this.TelefonNo = null;
            this.CepTelefon1 = null;
            this.CepTelefon2 = null;
            this.MailAdres = null;
            this.WebAdres = null;
            this.BankaSube = null;
            this.HesapSahip = null;
            this.Iban = null;
        },
        Refresh() {
            this.DataTable_render = false;
            this.$nextTick(() => {
                this.InitServis();
                this.DataTable_render = true;
            });
        },
        InitServis() {
            GlobalServis.GlobalServis("GET", "GetSirketTablosu", "").then(
                (res) => {
                    if (res.status == 200) {
                        this.SirketlerListesi = res.data;
                    } else if (res.status == 400) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Hata",
                            detail: "Önemli bir hata meydana geldi",
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Hata",
                            detail: "Bilinmeyen bir hata meydana geldi",
                            life: 3000,
                        });
                    }
                }
            )
        },
        onResize() {
            this.windowHeight = window.innerHeight
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
        this.InitServis();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    components: {
        DataTable,
    }
};
</script>

<style lang="scss" scoped>
.top-button-area .p-button.yeni {
    background: linear-gradient(to left,
            var(--purple-600) 50%,
            var(--purple-700) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--purple-700);
}

.top-button-area .p-button.yeni:hover {
    background-position: left bottom;
}

.top-button-area .p-button.yeni i {
    background-color: var(--purple-700);
}

.top-button-area .p-button.yeni:focus {
    box-shadow: 0 0 0 1px var(--purple-400);
}
</style>